import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { AuthBannerComponent } from "../../../Components/AuthBanner";
import { RouteStrings } from "../../../Routes/RouteStrings";
import { forgotPasswordService } from "../../../Services/AuthServices";
import Swal from "sweetalert2";
import { useRef } from "react";
import { useSelector } from "react-redux";

export const ForgotPassword = () => {
  const { isLogin } = useSelector((state) => state.UIStorage);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [email, setEmail] = useState("");

  const [isShowThankYou, setIsShowThankYou] = useState(false);

  useEffect(() => {
    if (isLogin) {
      navigate(RouteStrings.dashboard)
    }
  }, [isLogin]);

  const goToLogin = () => {
    navigate(RouteStrings.login);
  };

  const gotoreset = async (e) => {
    e.preventDefault();

    if (email === "") {
      setError({
        ...error,
        email: true,
      });
      setErrorMsg({
        email: "Please enter the email",
      });
    } else {
      let payload = {
        email: email,
      };

      let res = await forgotPasswordService(payload);

      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Email has been sent to reset the password",
        });
        inputRef.current.value = "";
        setIsShowThankYou(true);
        // navigate(RouteStrings.resetPassword);
      } else if (
        res.response.status === 404 ||
        res?.response?.status === 401 ||
        res?.response?.status === 400
      ) {
        inputRef.current.value = "";
        setIsShowThankYou(false);
        Swal.fire({
          icon: "error",
          text: `Error : ${res.response.data.message}`,
        });
      }
    }
  };

  const [error, setError] = useState({
    email: false,
  });
  const [errorMsg, setErrorMsg] = useState({
    email: "",
  });

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "email") {
      setError({
        ...error,
        email: false,
      });
    }
    setEmail(value);
  };

  return (
    <div className="container-fluid">
      <div className="div">
        <div className="row">
          <div className="col-md-6">
            <AuthBannerComponent screenHeading="Pennymead.com" />
          </div>
          <div className="col-md-6">
            {!isShowThankYou && (
              <>
                <div className="d-flex align-items-center justify-content-center h-100">
                  <div className="card forgotpassword_main px-3">
                    <h1 className="heading mb-2">Forgot Password</h1>

                    <div className="forgotpassword_form">
                      <form onSubmit={gotoreset}>
                        <div className="form_content mb-2">
                          <label htmlFor="">Email</label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            ref={inputRef}
                            onChange={handleChange}
                            className="input"
                            placeholder="Please enter your email"
                          />
                          {error.email && (
                            <>
                              <p className="text-danger mb-0">
                                {errorMsg.email}
                              </p>
                            </>
                          )}
                        </div>

                        <div className="mb-2">
                          <button
                            className="btn btn-primary btn_login"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>

                        <div className="mb-2">
                          <p className="_backtologin mb-0" onClick={goToLogin}>
                            Back to login.
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}

            {isShowThankYou && (
              <>
                <ThankyouPage />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ThankyouPage = () => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="card forgotpassword_main px-3">
          <h5>Email Sent Successfully!</h5>

          <p>
            An Email with the link to reset the password has been sent your
            registered email id.
          </p>
          <p>Please check.</p>
        </div>
      </div>
    </>
  );
};
