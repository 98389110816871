import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from "redux-persist/lib/storage"
import { persistReducer } from 'redux-persist'
import thunk from "redux-thunk"
import { uiSessionReducer } from './reducers/reducers'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loader']
}

const persist = combineReducers({
  UIStorage: persistReducer(persistConfig, uiSessionReducer.reducer),
});


export const store = configureStore({
  reducer: persist,
  middleware: [thunk],
})


