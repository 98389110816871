import React, { useEffect, useState } from "react";
import { AuthBannerComponent } from "../../../Components/AuthBanner";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../Routes/RouteStrings";
import "./styles.scss";

import Swal from "sweetalert2";
import {
  ResendOtpService,
  verifyOTPService,
} from "../../../Services/AuthServices";
import { useDispatch, useSelector } from "react-redux";
import { loggedIn, setAuth } from "../../../Store/reducers/reducers";
// import { pennymead_SetLocal } from "../../../Utils/LocalStorage";
import BounceLoader from "react-spinners/BounceLoader";

// !SPINNERS

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export const TwoFactorAuth = () => {
  const { isLogin } = useSelector((state) => state.UIStorage);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (isLogin) {
      navigate(RouteStrings.dashboard)
    } else {
      setUserData(location.state?.data?.userinfo);
    }
  }, [isLogin]);


  const [otp, setOtp] = useState("");

  const [error, setError] = useState({
    otp: false,
  });

  const [errorMsg, setErrorMsg] = useState({
    otp: "",
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const backtoLogin = () => {
    navigate(RouteStrings.login);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "otp") {
      setError({
        ...error,
        otp: false,
      });
      setOtp(value);
    }
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    // if successfull then disbale the button for 1 and 1/2 min

    let res = await ResendOtpService(userData.email);

    if (res?.status === 200 || res?.status === 201) {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 90 * 1000);

      Swal.fire({
        icon: "success",
        text: `${res?.data?.message}`,
      });
    } else {
      // console.log("res========>", res);
      Swal.fire({
        icon: "error",
        text: `Error : ${res?.data?.message}`,
      });
    }

    // for one and half min 90 * 1000
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp === "") {
      setError({
        ...error,
        otp: true,
      });
      setErrorMsg({
        otp: "Please enter the OTP",
      });
    } else {
      setLoading(true);
      let payload = {
        email: userData.email,
        otp: otp,
      };

      let res = await verifyOTPService(payload);
      // console.log("🚀 ~ file: index.js:111 ~ handleSubmit ~ res:", res)

      if (res.status === 200) {
        setLoading(false);
        // pennymead_SetLocal("token", res.data.accesstoken);
        // pennymead_SetLocal("refreshtoken", res.data.refreshtoken);
        Swal.fire({
          icon: "success",
          text: "OTP Verifed Successfully!",
        });
        dispatch(setAuth({
          isLogin: true,
          auth: { accesstoken: res.data.accesstoken, refreshtoken: res.data.refreshtoken },
        }));
        navigate(RouteStrings.dashboard, { replace: true });
      } else if (res.response.status === 400) {
        setLoading(false);
        // console.log("error");
        Swal.fire({
          icon: "error",
          // title: res?.status,
          text: `Error! ${res.response.data.message}`,
        });
      }
    }
  };

  return (
    <div>
      <div className="div container-fluid">
        <div className="row">
          <div className="col-md-6">
            <AuthBannerComponent screenHeading="Pennymead.com" />
          </div>
          {loading ? (
            <>
              <div className="d-flex justify-content-center align-items-center loaderCSS w-50">
                <BounceLoader
                  color="#873900"
                  loading={loading}
                  cssOverride={override}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-6">

                <div className="d-flex align-items-center justify-content-center h-100">
                  {(userData && Object.keys(userData).length > 1) ? <>
                    <div className="card login_main px-3">
                      <h1 className="heading mb-0">Enter OTP!</h1>
                      <p className="_text">
                        Please enter the OTP which was sent to your email.
                      </p>

                      <div className="otp_form">
                        {/* <form> */}
                        <div className="form_content mb-2">
                          <label htmlFor="">OTP</label>
                          <input
                            type="text"
                            name="otp"
                            id="otp"
                            onChange={handleChange}
                            className="input"
                            placeholder="Please enter your OTP"
                          />

                          {error.otp && (
                            <>
                              <p className="text-danger mb-0">{errorMsg.otp}</p>
                            </>
                          )}
                        </div>

                        <div className="mb-2">
                          <button
                            id="otpbtn"
                            className="otpbtn"
                            onClick={resendOTP}
                            disabled={isDisabled}
                          >
                            Resend OTP?
                          </button>
                        </div>

                        <div className="mb-2">
                          <button
                            className="btn btn-primary btn_login"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>

                        <div className="mb-2">
                          <p className="_backtologin mb-0" onClick={backtoLogin}>
                            <u> Back to login.</u>
                          </p>
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                  </> : <>
                    <div className="card login_main p-3">
                      <div className="mb-2">
                        <h6 className="_backtologin mb-0 cursor-pointer" onClick={backtoLogin}>
                          <u> Back to login</u>
                        </h6>
                      </div>
                    </div>
                  </>}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
