import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { CTAButton } from "../../../Components/CTAButton";
import { useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../Routes/RouteStrings";
import { Images } from "../../../Assets/Images/imageslist";
import { EditItem } from "./EditItem";
import "./styles.scss";
import {
  AddNewStockService,
  AddStockImagesService,
  ChangeStockStatusService,
  CompleteExportService,
  CreatePrintedCatalogService,
  ExportDataImagesService,
  GetAllStockLocationsService,
  GetExportedStockDateService,
  GetSubcategoryByCategoryId,
  GetTotalActiveStockService,
  RemoveStockImageService,
  SearchStockService,
} from "../../../Services/StockServices";
import Swal from "sweetalert2";
import {
  GetAllCategoriesService,
  GetAllSubCategories,
} from "../../../Services/CategoryServices";
import Form from "react-bootstrap/Form";
import { pennymead_SetLocal } from "../../../Utils/LocalStorage";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { AddSimilarItem } from "./AddSimilarListing";

import imageCompression from "browser-image-compression";
import { ApiResponseHook } from "../../../Services/MainBaseServices";
import { configURL } from "../../../Services/configuration";
import Autocomplete from "react-autocomplete";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Store/reducers/reducers";

export const StockManagement = () => {
  const similarRef = useRef(null)
  const editRef = useRef(null)
  const listRef = useRef(null)
  const dispatch = useDispatch()

  const { getRequest, postRequest } = ApiResponseHook();

  const [imagesArr, setImageArr] = useState([]);

  const [startDate, setStartDate] = useState("");

  const navigate = useNavigate();

  const [showSearchDiv, setShowSearchDiv] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategorieslist, setSubCategorieslist] = useState([]);

  const [isEdit, setShowIsEdit] = useState(false);

  const [searchData, setSearchData] = useState([]);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [selectedCategoriesExport, setSelectedCategoriesExport] = useState([]);

  const [isExport, setIsExport] = useState(false);
  const [newFileData, setNewFileData] = useState("");
  const [websiteType, setWebSiteType] = useState({
    type: "",
  });

  const [showSimilar, setShowSimilar] = useState(false);

  const [pageDataIndex, setPageIndex] = useState(0);

  const [pageCount, setPageCount] = useState(0);

  const [exportedDate, setExportedDate] = useState("");

  const handleShow = () => {
    navigate(`/${RouteStrings.createPrintedCatalog}`);
  };

  const [imagesType, setImagesType] = useState({
    imagesType: "",
  });

  const [zipData, setZipData] = useState([]);
  const [stockLocations, setStockLocations] = useState([]);

  const [value, setValue] = useState("");

  const [selectedCustNo, setSelectedCustNo] = useState("");

  const [activeStock, setActiveStock] = useState("");

  const [showExportModal, setShowExportModal] = useState(false);
  const [searchParam, setSearchParam] = useState({
    author: "",
    title: "",
    description: "",
    stock_reference: "",
    vendor: "",
    date_added: "",
    // location: "",
    category: "",
    sub_category: "",
    for_item: "",
    price: "",
    instock: "",
    page: pageDataIndex,
  });
  const [toEditData, setEditData] = useState([]);

  useEffect(() => {
    getAllCategoriesList();
    getexported_date();
    get_all_stock_locations();
    getActiveStock();
  }, []);


  useEffect(() => {
    if (showSimilar && similarRef.current) {
      similarRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [showSimilar]);

  useEffect(() => {
    if (isEdit && editRef.current) {
      editRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [isEdit]);

  useEffect(() => {
    if (showSearchDiv && listRef.current) {
      listRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [showSearchDiv]);

  const handleCloseExportModal = () => {
    setSelectedCategoriesExport([]);
    setIsExport(false);
    setShowExportModal(false);
  };
  const handleShowExportModal = () => setShowExportModal(true);

  const get_all_stock_locations = async () => {
    let res = await GetAllStockLocationsService();

    if (res?.status === 200 || res?.status === 201) {
      setStockLocations(res?.data?.data);
    } else {
      setStockLocations([]);
    }
  };

  const getActiveStock = async () => {
    let response = await getRequest(configURL.get_all_total_active_stocks);

    if (response?.status === 200 || response?.status === 201) {
      setActiveStock(response?.data?.data?.total);
    }
  };

  const getexported_date = async () => {
    let res = await GetExportedStockDateService();

    let value = res?.data?.data[0]?.value;

    let _date = new Date(value * 1000);

    const day = _date.getDate();
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    setExportedDate(formattedDate);
  };

  const donoshowSearchDiv = (data) => {
    setShowSearchDiv(true);
    setShowIsEdit(false);
  };

  const getAllCategoriesList = async () => {
    let res = await GetAllCategoriesService();
    setCategoriesList(res?.data?.data);
  };

  const getAllSubcategoryList = async (value) => {
    let res = await GetAllSubCategories();

    if (res.status === 201 || res.status === 200) {
      setSubCategorieslist(res?.data?.data || []);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };


  const gotoaddnew = () => {
    navigate(`/${RouteStrings.addNewItem}`);
  };

  const handleChange = (e) => {
    let { name, value, id } = e.target;

    if (name === "author") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        author: value || "",
      });
    }
    if (name === "title") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        title: value || "",
      });
    }
    if (name === "description") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        description: value || "",
      });
    }
    if (name === "stock_reference") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        stock_reference: value || "",
      });
    }
    if (name === "vendor") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        vendor: value || "",
      });
    }

    if (name === "category") {
      setPageIndex(0);
      getAllSubcategoryList(value);
      setSearchParam({
        ...searchParam,
        category: value || "",
      });
    }
    if (name === "subcategory") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        sub_category: value || "",
      });
    }
    if (name === "radio") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        for_item: value || "",
      });
    }

    if (name === "priceinp") {
      setPageIndex(0);
      setError(false);
      setSearchParam({
        ...searchParam,
        price: value || "",
      });
    }
    if (name === "instock") {
      setPageIndex(0);
      setSearchParam({
        ...searchParam,
        instock: value || "",
      });
    }
  };

  const searchStockFun = async (pageid) => {
    let _page = pageid + 1;

    let payload = {
      author: searchParam.author || "",
      title: searchParam.title || "",
      description: searchParam.description || "",
      stock_reference: searchParam.stock_reference || "",
      vendor: searchParam.vendor || "",
      date_added: searchParam.date_added || "",
      location: value || "",
      category: searchParam.category || "",
      sub_category: searchParam.sub_category || "",
      for_item: searchParam.for_item || "",
      price: searchParam.price || "",
      instock: searchParam.instock || "",
      page: _page,
    };

    if (
      (payload.for_item === "1" || payload.for_item === "2") &&
      payload.price === ""
    ) {
      setError(true);
      setErrorMsg("Please add the price");
    } else {
      setError(false);
      setShowSimilar(false);
      setShowIsEdit(false);
      pennymead_SetLocal("searchitems", payload);

      let res = await SearchStockService(payload);

      if (res?.status === 200 || res?.status === 201) {
        setShowSearchDiv(true);

        // setShowSearch(true);
        setSearchData(res?.data?.data?.stocks || []);

        setPageCount(Math.ceil(res?.data?.data?.totalpages));
      } else if (
        res?.response.status === 400 ||
        res?.response?.status === 404 ||
        res?.response?.status === 401 ||
        res?.response?.status === 500
      ) {
        setShowSearchDiv(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            res?.response?.status === 400
              ? "Please enter any of the fields to search"
              : `Error : ${res?.response?.data?.message || res?.response?.data?.error
              }`,
        });
        // console.log("error", res);
        setSearchData([]);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error `,
        });
        // console.log("errror", res);
      }
    }
  };

  function extractFileName(filePath) {
    const match = filePath.match(
      /images\/(.*?)\.(jpg|jpeg|png|gif|pdf|docx|txt|...)/i
    );

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  const deleteImageFun = async (data, itemdata) => {
    const filePath = data;
    const fileName = extractFileName(filePath);

    let res = await RemoveStockImageService(itemdata.sysid, fileName);

    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Deleted the image successfully!`,
      });
      searchStockFun(pageDataIndex);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };

  const handleStockImage = async (e) => {
    setError({
      ...error,
      stockref: false,
    });

    showLoading();
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true,
      maxWidthOrHeight: 1920,
    };
    try {
      if (imageFile.size > 512000) {
        const compressedFile = await imageCompression(imageFile, options);

        const imageObj = {
          file: compressedFile,
        };

        if (imagesArr.length > 0) {
          imagesArr[0] = imageObj;
        } else {
          imagesArr.push(imageObj);
        }
      } else {

        const imageObj = {
          file: imageFile,
        };

        if (imagesArr.length > 0) {
          imagesArr[0] = imageObj;
        } else {
          imagesArr.push(imageObj);
        }
      }
      showLoaded();
    } catch (error) {
      // console.log(error);
    }
  };

  const handleButtonClick = async (data) => {
    try {
      dispatch(setLoader(true))
      if (imagesArr.length > 0) {
        const formData = new FormData();
        formData.append("sysid", data.sysid);

        imagesArr.forEach((item, index) => {
          formData.append("stockref[]", item.file);
        });

        let res = await AddStockImagesService(formData);

        if (res.status === 201 || res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `Added New Image`,
          });
          navigate(`/${RouteStrings.stockManagement}`);
          searchStockFun(pageDataIndex);
          (document.getElementById('handlestockimagelist')).value = "";
          setImageArr([])
        } else if (
          res.response.status === 400 ||
          res.response.status === 401 ||
          res.response.status === 404
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Error ${res.response?.data?.message}`,
          });

          // console.log("error", res);
        } else {
          // console.log("errror", res);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `Error`,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Please select the image`,
        });
      }
    } catch (err) { } finally {
      dispatch(setLoader(false))
    }
  };

  const stockDeleteFun = async (item) => {
    let res = await ChangeStockStatusService(item.sysid, item.status);
    if (res.status === 201 || res.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${item?.status === "1"
          ? "Deleted Successfully"
          : "Activated Successfully"
          }`,
      });
      searchStockFun(pageDataIndex);
    } else if (
      res.response.status === 400 ||
      res.response.status === 401 ||
      res.response.status === 404
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error ${res.response?.data?.message}`,
      });
      // console.log("error", res);
    } else {
      // console.log("errror", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Error`,
      });
    }
  };



  const showEdit = (data) => {
    setShowIsEdit(true);
    setShowSearchDiv(false);
    setEditData(data);
  };

  const showSearchDivFun = () => {
    setShowSearchDiv(true);
    setShowIsEdit(false);
  };

  const handleSelectedCategoriesForExport = (item) => {
    const isCategorySelected = selectedCategoriesExport.includes(item);

    if (isCategorySelected) {
      setSelectedCategoriesExport(
        selectedCategoriesExport.filter((category) => category !== item)
      );
    } else {
      setSelectedCategoriesExport([...selectedCategoriesExport, item]);
    }
  };


  const showLoading = () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false, // Prevent closing the dialog by clicking outside
      showConfirmButton: false, // Hide the confirm button
      showCancelButton: false, // Hide the cancel button
      onOpen: () => {
        Swal.showLoading();
      },
      html: ` <div class="loader-container">
      <div class="loader"></div>
    </div>`,
    });
  };

  const showLoaded = () => {
    Swal.fire({
      title: "Loaded!",
      icon: "success",
      // html: // You can use different icons or customize as needed
    });
  };


  const convertDataToText = (data) => {
    // Iterate through the data and format it as plain text
    const textData = data
      .map((item, index) => {
        return `${item.stockref}~${item.author}${item.author2 && `,${item.author2}`}~${item.title}${item.longtitle ? `, ${item.longtitle}` : ""}~${item.publisher}${item.pubdate ? `, ${item.pubdate}` : ""}~${item.description} ${item.footnote || ""}~${item.price}~${item.keywords}`;
      }).join("\n");

    return textData;
  };

  const exportFun = async () => {
    let selectedCategories = selectedCategoriesExport.join(",");

    let paylod = {
      catid: selectedCategories,
      filetype: websiteType.type,
      exportype: imagesType.imagesType,
    };

    if (paylod.catid) {

      let res = await postRequest(configURL.export_data_images, paylod);

      if (res?.status === 200 || res?.status === 201) {
        setIsExport(true);
        let strarr = res?.data?.data?.stocks;
        let zipArr = res?.data?.data?.zipfiles;
        let data_ = convertDataToText(strarr);
        setZipData(zipArr);
        setNewFileData(data_);
      } else {
        Swal.fire({
          icon: "error",

          text: res?.response?.data?.message || `Error`,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: `Please select atleast 1 category`,
      });
    }
  };

  const handleWebSiteABE = (e) => {
    setWebSiteType({
      type: e.target.value,
    });
  };

  const handleChangeAllImages = (e) => {
    setImagesType({
      imagesType: e.target.value,
    });
  };

  const handleChangeImageSince = (e) => {
    setImagesType({
      imagesType: e.target.value,
    });
  };

  const handleSelectYear = (date) => {
    setStartDate(date);
    let _year = date?.getFullYear();
    setSearchParam({
      ...searchParam,
      date_added: _year || "",
    });
  };

  const handleShowSimilar = (data) => {
    setShowSimilar(true);
    setShowSearchDiv(false);
    setShowIsEdit(false);
    setEditData(data);

  };

  const hidesimilardiv = () => {
    setShowSearchDiv(true);
    setShowSimilar(false);
  };

  const callNextPage = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPageIndex(() => pageDataIndex + 1);
    await searchStockFun(pageDataIndex + 1);
  };

  const callPrevPage = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setPageIndex(() => pageDataIndex > 0 && pageDataIndex - 1);
    await searchStockFun(pageDataIndex - 1);
  };

  const emptyData = () => {
    setSearchData();
  };

  const completeExportFun = async () => {
    let res = await CompleteExportService();

    if (res?.status === 200 || res?.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${res?.data?.message || "Data exported successfully"}`,
      });
      setIsExport(false);
      // document.getElementById("closeModalExport").click();
      setShowExportModal(false)
      setSelectedCategoriesExport([]);
      getexported_date()
    } else {
      // console.log("response------------>", res);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Data exported successfully",
      });
    }
  };

  return (
    <div>
      <div>
        <BreadCrumb routename="Stock Management" />
      </div>

      <div className="my-2 w-100 d-flex justify-content-end">
        <div className="mx-2">
          <CTAButton
            name="Add New Item"
            handleClick={gotoaddnew}
            id="addnewitembtn"
          />
        </div>
        <div className="mx-1">
          <button
            className="btn btn_export"
            onClick={handleShowExportModal}
            id="exportmodalbtn"
          >
            Export
          </button>
        </div>
        <div className="mx-1">
          <button
            className="btn btn_export"
            onClick={handleShow}
            id="createprintedcatalogbtn"
          >
            Created Printed Catalog
          </button>
        </div>
      </div>
      <div className="mb-2">
        <h5 className="subHeading">
          Stock database contains <strong>{activeStock}</strong> active items
        </h5>
      </div>

      {/*  SEARCH FOR AN ITEM! */}

      <div className="searchItemDiv">
        <label className="mb-1">Search for an item</label>
        <div className=" maxwidth-800">

          <div className="row mb-3">
            <div className="col-md-3 mb-3">
              <div className="">
                <label htmlFor="">Author</label>
                <input
                  type="text"
                  name="author"
                  id="author"
                  onChange={handleChange}
                  placeholder="Enter author"
                  className="inputTag"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  onChange={handleChange}
                  placeholder="Enter title"
                  className="inputTag"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <label htmlFor="">Description</label>
                <input
                  type="text"
                  name="description"
                  onChange={handleChange}
                  id="description"
                  placeholder="Enter description"
                  className="inputTag"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <label htmlFor="">Stock reference</label>
                <input
                  type="text"
                  name="stock_reference"
                  onChange={handleChange}
                  id="stock_reference"
                  placeholder="Enter reference"
                  className="inputTag"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <label htmlFor="">Vendor</label>
                <input
                  type="text"
                  name="vendor"
                  id="vendor"
                  onChange={handleChange}
                  placeholder="Enter vendor"
                  className="inputTag"
                />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div>
                <label htmlFor="">Published Year</label>

                <DatePicker
                  selected={startDate}
                  name="date"
                  onChange={(date) => handleSelectYear(date)}
                  className="inputTag"
                  dateFormat="yyyy"
                  placeholderText="Select Year"
                  showYearPicker
                  yearDropdownItemNumber={10}
                />
              </div>
            </div>

            <div className="col-md-3 mb-3">
              <div className="selectmaindiv">
                <label htmlFor="">Location</label>
                <div
                  style={{
                    outline: "none",
                    border: "1px solid #873900",
                    height: "36px",
                    width: "100%",
                    paddingLeft: "8px"
                  }}
                >
                  <div>
                    <Autocomplete
                      items={stockLocations}
                      shouldItemRender={(item, value) =>
                        item.location.toLowerCase().includes(value.toLowerCase())
                      }
                      getItemValue={(item) => item.location}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className="dropdowndiv"
                          style={{
                            background: isHighlighted ? "#bcf5bc" : "white",
                          }}
                          key={item.location}
                        >
                          {item.location}
                        </div>
                      )}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      onSelect={(val) => {
                        setSelectedCustNo(val); // Set the selected custno
                        const selected = stockLocations.find(
                          (customer) => customer.location === val
                        );
                        setValue(selected ? selected.location : "");
                      }}
                      inputProps={{
                        style: {
                          width: "100%",
                          height: "36px",
                          background: "none",
                          border: "none",
                          outline: "none",
                        },
                        placeholder: "Search location",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex mt-2 w-100 align-items-center">
          <div class="form-group">
            <label for="exampleFormControlSelect1">within</label>
            <select
              class="form-control _inp"
              id="exampleFormControlSelect1"
              name="category"
              onChange={handleChange}
            >
              <option disabled selected>
                Select Category
              </option>
              {categoriesList?.map((item) => {
                return (
                  <>
                    <option key={item?.reference} value={item?.reference}>
                      {item?.name}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          {/* filter subcategory */}

          <div class="form-group ml-2">
            <label for="exampleFormControlSelect1" className="">
              Subcategory
            </label>
            <select
              class="form-control _inp"
              id="exampleFormControlSelect2"
              name="subcategory"
              onChange={handleChange}
            >
              <option disabled selected>
                Select Subcategory
              </option>
              {subCategorieslist?.map((item, i) => {
                return (
                  <>
                    <option key={i} value={item?.reference}>
                      {item?.name}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <Form className="d-flex">
            <Form.Check
              type="radio"
              name="radio"
              id="alluser"
              value="0"
              onClick={(e) => handleChange(e)}
              label="All Prices"
              className="mr-2"
            />

            <Form.Check
              type="radio"
              name="radio"
              label="Under"
              id="under"
              className="mr-2"
              value="1"
              onClick={(e) => handleChange(e)}
            />
            <Form.Check
              type="radio"
              name="radio"
              label="Over"
              id="over"
              value="2"
              className="mr-2"
              onClick={(e) => handleChange(e)}
            />
          </Form>

          <div className="mr-2 position-relative">
            <input
              type="number"
              name="priceinp"
              id="priceinp"
              onChange={handleChange}
              className="inputTag px-3"
            />

            <p className="mb-0 _dollarsym">£</p>
          </div>

          <Form>
            <Form.Check
              type="checkbox"
              id="stock"
              label="In Stock"
              name="instock"
              value="1"
              onChange={handleChange}
            />
          </Form>

          <div className="mx-3">
            <button
              className="btn btn-primary rounded-0"
              onClick={() => searchStockFun(pageDataIndex)}
              id="searchstockbtn"
            >
              Search
            </button>
          </div>
        </div>

        {error && (
          <>
            <p className="mb-0 text-danger">{errorMsg}</p>
          </>
        )}
      </div>

      {!showSearchDiv && (
        <>
          <div className="showSearchResultsDiv my-3">
            <h6>Please search for any of the item in stock</h6>
          </div>
        </>
      )}

      {/*  when the results are found */}
      {showSearchDiv && (
        <div ref={listRef}>
          <h6 className="mt-2">Search Results</h6>

          {searchData?.map((item) => {
            return (
              <>
                <div className="my-3 card p-2 order_card">
                  <div className="">
                    <div className="hero my-2 w-100">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="subHeading">{item?.author}</h5>

                        {item?.status === "1" ? (
                          <>
                            <button
                              className="btn btn-danger mx-2 rounded-0"
                              onClick={() => stockDeleteFun(item)}
                              id="stockdeletebtn"
                            >
                              Delete
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-success mx-2 rounded-0"
                              id="stockactivatebtn"
                              onClick={() => stockDeleteFun(item)}
                            >
                              Activate
                            </button>
                          </>
                        )}
                      </div>

                      <p className="item_description">{item?.title}</p>
                      <p className="item_description">{item?.description}</p>

                      <div className="row">
                        {item?.images?.map((img) => {
                          return (
                            <>
                              <div className="col-md-3">
                                <div className="position-relative my-3 text-right imagedivstock">
                                  <img
                                    src={img || Images.stockBook}
                                    alt={`${item?.author} ${item?.title}`}
                                    className="img_ img-fluid"
                                  />
                                  <img
                                    src={Images.deleteIcon}
                                    alt=""
                                    className="deleteImgIcon"
                                    onClick={() => {
                                      deleteImageFun(img, item);
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>

                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Stock Reference</td>
                            <td>{item?.stockref || "--"}</td>
                          </tr>
                          <tr>
                            <td>Quantity Available</td>
                            <td>{item?.quantity || "--"}</td>
                          </tr>
                          <tr>
                            <td>Category</td>
                            <td> {item?.category_name || "no category"}</td>
                          </tr>
                          <tr>
                            <td>Keywords</td>
                            <td> {item?.actual_keywords || "no category"}</td>

                            {/* <td>
                              {extractKeywords(item?.actual_keywords) || "No keywords"}
                            </td> */}
                          </tr>
                          <tr>
                            <td>Vendor</td>
                            <td>{item?.vendor || "no vendor"}</td>
                          </tr>
                          <tr>
                            <td>Pubdate</td>
                            <td>{item?.pubdate || "no date"}</td>
                          </tr>
                          <tr>
                            <td>Date Added</td>
                            <td>{item?.adddate || "no date"}</td>
                          </tr>
                          <tr>
                            <td>Price</td>
                            <td>
                              {item?.price ? `£ ${item.price}` : "no date"}
                            </td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td>
                              {item?.status === "1" ? (
                                <>
                                  <p className="mb-0 active_">Active</p>
                                </>
                              ) : (
                                <>
                                  <p className="mb-0 inactive_">Inactive</p>
                                </>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <p className="my-2">
                        The parts of this listing displayed in red do not appear
                        on the main site.
                      </p>

                      {item?.status === "1" && (
                        <>
                          <button
                            className="btn btn-info rounded-0 mr-2"
                            id="showeditbtn"
                            onClick={() => showEdit(item)}
                          >
                            Edit
                          </button>
                        </>
                      )}

                      <button
                        className="btn btn-secondary rounded-0 mr-2"
                        id="addsimilarlistingbtnlist"
                        onClick={() => handleShowSimilar(item)}
                      >
                        Add Similar Listing
                      </button>
                      <div className="d-inline">
                        <input
                          type="file"
                          // ref={fileInputRef}
                          // style={{ display: "none" }}
                          // accept=".png"
                          id="handlestockimagelist"
                          onChange={handleStockImage}
                        />
                        <button
                          className="btn btn-info rounded-0"
                          id="uplaodbtnfile"
                          onClick={() => handleButtonClick(item)}
                        >
                          Upload File
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}

      {/* !!!!!!!!!!!!!!!!!!! Edit Stock View !!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
      {isEdit && (
        <div ref={editRef}>
          <EditItem
            data={toEditData}
            searchStockFun={() => searchStockFun(pageDataIndex)}
            donoshowSearchDiv={donoshowSearchDiv}
          />
          <button
            className="btn btn-danger rounded-0 mt-2"
            onClick={showSearchDivFun}
            id="canceleditbtn"
          >
            Cancel
          </button>
        </div>
      )}

      {showSimilar && (
        <div ref={similarRef}>
          <AddSimilarItem
            data={toEditData}
            donoshowSearchDiv={donoshowSearchDiv}
            hidesimilardiv={hidesimilardiv}
            emptyData={emptyData}
          />
        </div>
      )}

      {/* !!!!!!!!!!!!!!!!!!!!!!<p>add the pagination here!</p>!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

      {!isEdit && !showSimilar && searchData?.length > 0 && (
        <>
          <div>
            <div className="d-flex align-items-center mb-3">
              <div className="w-100 text-center mt-3 mb-1">
                {/* <button
                  className="mx-1 skipToBtn"
                  onClick={() => goToLastPage(0)}
                  disabled={!(pageDataIndex > 0)}
                >
                  {"<<"}
                </button> */}
                <button
                  className="mx-1 actionBtn"
                  onClick={() => { callPrevPage() }}
                  id="prevbtnpagination"
                  disabled={!(pageDataIndex > 0)}
                >
                  Prev
                </button>
                <span className="mx-2 pageNumber">
                  <strong>{pageDataIndex + 1}</strong>
                </span>
                <button
                  className="mx-1 actionBtn"
                  onClick={() => {
                    callNextPage();
                  }}
                  id="nextbtnpagination"
                  disabled={pageDataIndex >= pageCount - 1}
                >
                  Next
                </button>
                {/* <button
                  className="skipToBtn"
                  onClick={() => goToLastPage(pageCount - 1)}
                  disabled={pageDataIndex >= pageCount - 1}
                >
                  {">>"}
                </button> */}
              </div>
            </div>
          </div>
        </>
      )}

      {/* !!!!!!!!!!!!!!!!!!! Edit Stock View !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}
      <Modal show={showExportModal} onHide={handleCloseExportModal}>
        <Modal.Header>
          <Modal.Title>Export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {isExport ? (
              <>
                <ExportLinkScreen data={newFileData} zipData={zipData} />
              </>
            ) : (
              <>
                {categoriesList?.map((item) => {
                  return (
                    <>
                      <div class="form-check form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value={item?.reference}
                          id={item.reference}
                          onClick={() =>
                            handleSelectedCategoriesForExport(item?.reference)
                          }
                          checked={selectedCategoriesExport.includes(
                            item.reference
                          )}
                        />
                        <label class="form-check-label" for="inlineCheckbox1">
                          {item?.name}
                        </label>
                      </div>
                    </>
                  );
                })}

                <Form className="">
                  <div className="my-3">
                    <Form.Check
                      type="radio"
                      name="radio"
                      id="abebooks"
                      value="1"
                      onClick={(e) => handleWebSiteABE(e)}
                      label="File is for ukbookworld or ABEbooks website"
                      className="mr-2"
                      checked
                    />
                  </div>
                </Form>

                <Form>
                  <Form.Check
                    type="radio"
                    name="radio"
                    id="allimages"
                    value="0"
                    onClick={(e) => handleChangeAllImages(e)}
                    label="Export all images"
                    className="mr-2"
                    defaultChecked={true}
                  />
                  <Form.Check
                    type="radio"
                    name="radio"
                    id="allimagesince"
                    value="1"
                    onClick={(e) => handleChangeImageSince(e)}
                    label={` Only export images for items added since the last export (Last
                        export done on ${exportedDate})`}
                    className="mr-2"
                  />
                </Form>
              </>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>

          {isExport ? (
            <>
              <div className="px-2 my-2 d-flex align-items-center justify-content-between">
                <button
                  className="btn btn-danger rounded-0 mr-2"
                  onClick={() => {
                    setIsExport(false);
                  }}
                  id="backbuttonexport"
                >
                  Back
                </button>

                <button
                  className="btn btn-info rounded-0"
                  id="completeexportbtn"
                  onClick={completeExportFun}
                >
                  Complete Export
                </button>
              </div>
            </>
          ) : (
            <>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={() => handleCloseExportModal()}
                  id="closeModalExport"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  id="handleexportbutton"
                  onClick={exportFun}
                >
                  Export
                </button>
              </div>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const ExportLinkScreen = ({ data, zipData }) => {
  useEffect(() => {
    setZipFiles(zipData);
  }, []);

  const [zipFiles, setZipFiles] = useState([]);

  const newFileFun = (data) => {
    let content = data;
    const newWindow = window.open("", "_blank");
    newWindow.document.write(
      `<button id="saveButton">Save</button><br/><pre>${content}</pre>`
    );

    newWindow.document.getElementById("saveButton").onclick = () => {
      const blob = new Blob([content], { type: "text/plain" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "export.txt";
      a.click();

      URL.revokeObjectURL(url);
      newWindow.close();
    };
  };

  return (
    <>
      <div className="links_div">
        <p>your export file has been created.</p>

        <a className="viewFile_href" onClick={() => newFileFun(data)}>
          Click here to view the file
        </a>

        <p className="my-2">
          Click on the link to download the images in the zip.
        </p>
        {zipFiles?.flat(10)?.map((item) => {
          return (
            <>
              <div className="d-flex flex-column my-2">
                <a href={item}>{item}</a>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
