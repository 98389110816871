import React, { useState, useEffect, useRef } from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from "react-table";
import { configURL } from "../../../../Services/configuration";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteStrings } from "../../../../Routes/RouteStrings";

import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import { Images } from "../../../../Assets/Images/imageslist";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { PennymeadContext } from "../../../../Context";
import { Checkbox } from "../OrderTable/Checkbox";
import { ListingOrdersComponent } from "../ListingOrders";
import { ListingPackedOrders } from "../ListingOrders/ListingPackedOrders";
import { ApiResponseHook } from "../../../../Services/MainBaseServices";


function ServerSideOrdersListComponent({
  fetchCustomersData,
  fetchCompletedOrders,
  getAllOrdersAwatingInvoice,
  getAllOrdersAwatingPayment,
  getAllPackedOrdersAwatingInvoice,
  getAllPackedOrdersAwatingPayment,
  getAllPackedCompletedOrders,
  fetchPackedAllOrders,
  getAllUnpackedPaidOrders,
  getAllPackedPaidOrders,
}) {
  const { getRequest, postRequest } = ApiResponseHook();

  // let [loading, setLoading] = useState(false);

  const { isPacked, setIsPacked } = React.useContext(PennymeadContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [key, setKey] = useState(isPacked);
  // console.log("🚀 ~ key:", key)

  const [selectedFilter, setSelectedFilter] = useState("");
  // console.log("🚀 ~ selectedFilter:", selectedFilter)

  const [selectedFilterPacked, setSelectedFilterPacked] = useState("");

  // STATES
  const [query, setQuery] = useState("");

  const [searchText, setSearchText] = useState("");

  const [checkedOrders, setCheckedOrders] = useState([]);

  // State to store data and pagination information
  const [data, setData] = useState([]);
  // console.log("🚀 ~ data:", data)

  const [packedData, setPackedData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  // console.log("🚀 ~ pageCount:", pageCount)

  const [pageDataSize, setPageDataSize] = useState(10);

  const [pageDataIndex, setPageIndex] = useState(0);

  const [dateRangePageIndex, setDateRangePageIndex] = useState(1);

  const [pageDataIndexPacked, setPageIndexPacked] = useState(0);

  const [viewFromDate, setViewFromDate] = useState("");
  const [viewToDate, setViewToDate] = useState("");
  const viewToDateRef = useRef();
  const viewFromDateRef = useRef();

  const viewToSummaryDateRef = useRef();
  const viewFromSummaryDateRef = useRef();
  const [summaryFromDate, setSummaryFromDate] = useState("");
  const [summaryToDate, setSummaryToDate] = useState("");

  const [allPackedOrders, setAllPackedOrders] = useState([]);

  const orderCheckRef = useRef();
  const customerCheckRef = useRef();
  const searchInpRef = useRef();

  const selectfilterforunpackedref = useRef();
  const selectfilterforpackedref = useRef();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "slno",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Order No",
        accessor: "orderno",
      },
      {
        Header: "Name",
        accessor: (d) => {
          if (d.name === "") {
            return `No Name`;
          } else {
            return `${d.name}`;
          }
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },

      {
        Header: "Order Total",
        accessor: (d) => {
          if (d.grand_total) {
            return `£${d.grand_total}`;
          } else {
            return `${"---"}`;
          }
        },
      },

      {
        Header: "Order Status",
        accessor: (d) => {
          if (d.status === "1") {
            return `Active`;
          } else if (d.status === "0") {
            return `Deleted`;
          }
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: (tableProps) => (
          <div className="d-flex flex-row align-items-center">
            {tableProps.cell.row.original.status === "1" && (
              <>
                <a
                  className="mx-1 viewButton"
                  onClick={() => gotoView(tableProps.cell.row.original)}
                >
                  View
                </a>
              </>
            )}
          </div>
        ),
      },
    ],
    [key]
  );

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Packed orders columns !!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  const columnsPacked = React.useMemo(
    () => [
      {
        Header: "S.No.",
        accessor: "slno",
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: "Customer No",
        accessor: "custno",
      },
      {
        Header: "Package Id",
        accessor: "package_id",
      },
      {
        Header: "Name",
        accessor: (d) => {
          if (d.name === "") {
            return `No Name`;
          } else {
            return `${d.name}`;
          }
        },
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Total Items",
        accessor: "totalitem",
      },
      {
        Header: "Status",
        accessor: (d) => {
          if (d.status === "1") {
            return `Active`;
          } else {
            return `Deleted`;
          }
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: (tableProps) => (
          <div className="d-flex flex-row align-items-center">
            {tableProps.cell.row.original.status === "1" && (
              <>
                <a
                  className="mx-1 viewButton"
                  onClick={() => gotoView(tableProps.cell.row.original)}
                >
                  View
                </a>
              </>
            )}
          </div>
        ),
      },
    ],
    [key]
  );

  const selectedColumns = key === "unpacked" ? columns : columnsPacked;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    setGlobalFilter,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns: selectedColumns,
      data: key === "unpacked" ? data : packedData,
      initialState: { pageIndex: 0, pageSize: pageDataSize },
      manualPagination: true,
      pageCount,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect,
    (hooks) => {
      key === "unpacked" &&
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <Checkbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <Checkbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  const handleClearSelection = () => {
    toggleAllRowsSelected(false);
  };

  const fetchData = React.useCallback(async (pageDataIndex, pageDataSize) => {
    // setPageCount(0);
    // setLoading(true);


    // let reversedData = resp?.data?.data?.orders
    // ?.sort(
    //   (a, b) => b.orderno - a.orderno
    // );

    // console.log("reversedData=====>", reversedData)

    try {
      let resp = await fetchCustomersData(pageDataIndex + 1, pageDataSize);
      // console.log("resp=====>", resp)
      if (resp.status === 200 || resp?.status === 201) {
        // if (key === "packed") {
        setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        // }

        // if (key === "unpacked") {
        //   setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        // }

        // setLoading(false);
        // setData(resp?.data?.data?.orders || []);

        setData(resp?.data?.data?.orders || []);
      } else if (
        resp?.response?.status === 400 ||
        resp?.response?.status === 404 ||
        resp?.response?.status === 401
      ) {
        // setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `No data found.`,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.error("Error fetching data:", error);
    }
  }, []);

  const fetchPackedData = React.useCallback(
    async (pageDataIndexPacked, pageDataSize) => {
      // setPageCount(0);
      // setLoading(true);

      // let reversedData = resp?.data?.data?.packages?.sort(
      //   (a, b) => b.orderno - a.orderno
      // );

      try {
        // if (key === "packed") {
        let resp = await fetchPackedAllOrders(
          pageDataIndexPacked + 1,
          pageDataSize
        );
        // console.log("resp===>packed", resp)

        setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        // }
        // if (key === "unpacked") {
        //   setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        // }

        // setLoading(false);
        setAllPackedOrders(resp?.data?.data);
        // setPackedData(resp?.data?.data?.packages || []);
        setPackedData(resp?.data?.data?.packages || []);
      } catch (error) {
        // setLoading(false);
        console.error("Error fetching data:", error);
      }
    },
    []
  );

  // function to fetch the data for the completed orders
  const fetchDataCompletedOrders = React.useCallback(
    async (pageDataIndex, pageDataSize) => {
      // setLoading(true);
      let resp = await fetchCompletedOrders(pageDataIndex + 1, pageDataSize);

      let reversedData = resp?.data?.data?.orders?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        // setLoading(false);
        // setData(resp?.data?.data?.orders || []);
        setData(reversedData || []);

        setPageCount(Math.ceil(resp?.data?.data?.totalpages));
      } catch (error) {
        // setLoading(false);
        console.error("Error fetching data:", error);
      }
    },
    []
  );
  const fetchPackedDataCompletedOrders = React.useCallback(
    async (pageDataIndexPacked, pageDataSize) => {
      // setLoading(true);
      let resp = await getAllPackedCompletedOrders(
        pageDataIndexPacked + 1,
        pageDataSize
      );

      let reversedData = resp?.data?.data?.package?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        if (resp.status === 200 || resp?.status === 201) {
          // // setLoading(false);
          // setPackedData(resp?.data?.data?.package || []);
          setPackedData(reversedData || []);

          setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        } else if (
          resp?.response?.status === 400 ||
          resp?.response?.status === 404 ||
          resp?.response?.status === 401
        ) {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            // text: `Error ${resp.response?.data?.message}, No data found.`,
            text: `No data found.`,
          });
        }
      } catch (error) {
        // setLoading(false);
        console.error("Error fetching data:", error);
      }
    },
    []
  );

  const callOrdersfun = (key) => {
    if (key === "unpacked") {
      // setPageIndex(0);
      // setPageCount(0);
      setSelectedFilter("allorders");
      setData([]);
      fetchData(pageDataIndex, pageDataSize);
    } else if (key === "packed") {
      // setPageIndexPacked(0);
      // setPageCount(0);
      setSelectedFilterPacked("allorderspacked");
      setPackedData([]);
      fetchPackedData(pageDataIndexPacked);
    }
  };

  // !!!!!!!!!!! get all packed orders

  // function to fetch the data for the orders awating invoice
  const getAllDataOrdersAwatingInvoice = React.useCallback(
    async (pageDataIndex, pageDataSize) => {
      // setLoading(true);
      let resp = await getAllOrdersAwatingInvoice(
        pageDataIndex + 1,
        pageDataSize
      );

      let reversedData = resp?.data?.data?.orders?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        // setLoading(false);

        // setData(resp?.data?.data?.orders);
        setData(reversedData);

        setPageCount(Math.ceil(resp?.data?.data?.totalpages));
      } catch (error) {
        // setLoading(false);

        console.error("Error fetching data:", error);
      }
    },
    []
  );
  const getAllPackedDataOrdersAwatingInvoice = React.useCallback(
    async (pageDataIndexPacked, pageDataSize) => {
      // setLoading(true);
      let resp = await getAllPackedOrdersAwatingInvoice(
        pageDataIndexPacked + 1,
        pageDataSize
      );

      let reversedData = resp?.data?.data?.packages?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        if (resp.status === 200 || resp?.status === 201) {
          // setLoading(false);
          // setPackedData(resp?.data?.data?.packages || []);
          setPackedData(reversedData || []);
          setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        } else if (
          resp?.response?.status === 400 ||
          resp?.response?.status === 404 ||
          resp?.response?.status === 401
        ) {
          // setLoading(false);
          setPackedData([]);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `No data found.`,
          });
        }
      } catch (error) {
        // setLoading(false);

        console.error("Error fetching data:", error);
      }
    },
    []
  );
  // !function to fetch the data for the orders awating payment
  const getAllDataOrdersAwatingPayment = React.useCallback(
    async (pageDataIndex, pageDataSize) => {
      // setLoading(true);
      let resp = await getAllOrdersAwatingPayment(
        pageDataIndex + 1,
        pageDataSize
      );

      let reversedData = resp?.data?.data?.orders?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        if (resp.status === 200 || resp?.status === 201) {
          // setLoading(false);

          // setData(resp?.data?.data?.orders || []);
          setData(reversedData || []);

          setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        } else if (
          resp?.response?.status === 400 ||
          resp?.response?.status === 404 ||
          resp?.response?.status === 401
        ) {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            // text: `Error ${resp.response?.data?.message}, No data found.`,
            text: `No data found.`,
          });
        }
      } catch (error) {
        // setLoading(false);

        console.error("Error fetching data:", error);
      }
    },
    []
  );
  const getAllDataPaidOrders = React.useCallback(
    async (pageDataIndex, pageDataSize) => {
      // setLoading(true);
      let resp = await getAllUnpackedPaidOrders(
        pageDataIndex + 1,
        pageDataSize
      );

      let reversedData = resp?.data?.data?.orders?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        if (resp.status === 200 || resp?.status === 201) {
          // setLoading(false);

          // setData(resp?.data?.data?.orders);
          setData(reversedData);

          setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        } else if (
          resp?.response?.status === 400 ||
          resp?.response?.status === 404 ||
          resp?.response?.status === 401
        ) {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            // text: `Error ${resp.response?.data?.message}, No data found.`,
            text: `No data found.`,
          });
        }
      } catch (error) {
        // setLoading(false);

        console.error("Error fetching data:", error);
      }
    },
    []
  );

  const getAllPackedDataOrdersAwatingPayment = React.useCallback(
    async (pageDataIndexPacked, pageDataSize) => {
      // setLoading(true);
      let resp = await getAllPackedOrdersAwatingPayment(
        pageDataIndexPacked + 1,
        pageDataSize
      );

      let reversedData = resp?.data?.data?.packages?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        if (resp.status === 200 || resp?.status === 201) {
          // setLoading(false);

          // setPackedData(resp?.data?.data?.packages || []);
          setPackedData(reversedData || []);

          setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        } else if (
          resp?.response?.status === 400 ||
          resp?.response?.status === 404 ||
          resp?.response?.status === 401
        ) {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            // text: `Error ${resp.response?.data?.message}, No data found.`,
            text: `No data found.`,
          });
        }
      } catch (error) {
        // setLoading(false);

        console.error("Error fetching data:", error);
      }
    },
    []
  );
  const getAllPackedDataPaidOrders = React.useCallback(
    async (pageDataIndexPacked, pageDataSize) => {
      // setLoading(true);
      let resp = await getAllPackedPaidOrders(
        pageDataIndexPacked + 1,
        pageDataSize
      );

      let reversedData = resp?.data?.data?.package?.sort(
        (a, b) => b.orderno - a.orderno
      );

      try {
        if (resp.status === 200 || resp?.status === 201) {
          // setLoading(false);

          // setPackedData(resp?.data?.data?.package || []);
          setPackedData(reversedData || []);

          setPageCount(Math.ceil(resp?.data?.data?.totalpages));
        } else if (
          resp?.response?.status === 400 ||
          resp?.response?.status === 404 ||
          resp?.response?.status === 401
        ) {
          // setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            // text: `Error ${resp.response?.data?.message}, No data found.`,
            text: `No data found.`,
          });
        }
      } catch (error) {
        // setLoading(false);

        console.error("Error fetching data:", error);
      }
    },
    []
  );

  // Fetch data when the page index changes
  // useEffect(() => {
  //   if (key === "unpacked") {
  //     (selectedFilter == "allorders" || selectedFilter == "") &&
  //       setPageCount(0);
  //     fetchData(pageDataIndex, pageDataSize);
  //   }
  //   if (key === "packed") {
  //     (selectedFilterPacked == "allorderspacked" ||
  //       selectedFilterPacked == "") &&
  //       setPageCount(0);
  //     fetchPackedData(pageDataIndexPacked, pageDataSize);
  //   }
  // }, [pageDataIndex, pageDataSize, pageDataIndexPacked]);

  useEffect(() => {
    if (key === "unpacked") {
      (selectedFilter == "allorders" || selectedFilter == "") &&
        fetchData(pageDataIndex, pageDataSize);
    }
    if (key === "packed") {
      (selectedFilterPacked == "allorderspacked" ||
        selectedFilterPacked == "") &&
        fetchPackedData(pageDataIndexPacked, pageDataSize);
    }
  }, []);
  // pageDataIndex, pageDataSize, pageDataIndexPacked
  // useEffect(() => {
  //   setKey(isPacked);
  //   // setPageIndex(0);
  //   // setPageIndexPacked(0);
  //   // callOrdersfun(isPacked);
  // }, []);

  const gotoView = (data) => {
    navigate(`/${RouteStrings.viewOrder}`, {
      state: { data: data, isPacked: key },
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setQuery("0");
  };

  const handleChangeName = (e) => {
    setQuery("1");
  };

  // const gotoGraphicalSales = () => {
  //   navigate(`/${RouteStrings.graphicalSalesAnalysis}`);
  // };

  const searchFuntion = async () => {
    let payload = {
      search_key: query,
      search_value: searchText,
    };

    if (payload.search_key === "") {
      if (key === "unpacked") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Please select order or customer`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Please select package or customer`,
        });
      }
    } else if (payload.search_value === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please enter the search text`,
      });
    } else {
      let response = "";
      if (key === "packed") {
        // response = await SearchPackedOrdersServcice(payload, pageDataIndex + 1);
        response = await postRequest(
          `${configURL.search_packed_orders}/${pageDataIndex + 1}/`,
          payload
        );
      } else if (key === "unpacked") {
        // response = await SearchOrderService(payload);
        response = await postRequest(configURL.search_order, payload);
      }

      if (response?.status === 200 || response?.status === 201) {
        if (key === "packed") {
          setPackedData(response?.data?.data?.package);
          setPageCount(0);
        } else if (key === "unpacked") {
          if (response?.data?.data.length > 0) {
            response?.data?.data?.map((item) => {
              // if (item?.status === "0") {
              //   setData([]);
              //   setPageCount(0);
              // } else {
              //   setData(response?.data?.data || []);
              //   setPageCount(0);
              // }
              setPageCount(0);
              setData(response?.data?.data || []);
            });
          } else {
            setData([]);
            setPageCount(0);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `No Data Found!`,
            });
          }
        }
      } else if (response?.data?.data?.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error No Customers Found!`,
        });
      } else if (
        response?.response?.status === 400 ||
        response?.response?.status === 404 ||
        response?.response?.status === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${response?.data?.message || "No Data Found"}`,
        });
        if (key === "packed") {
          setPackedData([]);
          setPageCount(0);
        } else if (key === "unpacked") {
          setData([]);
          setPageCount(0);
        }
        // console.log("error", response);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error `,
        });
        // console.log("error", response);
      }
    }
  };

  // const handle select orders

  // !!!!!!!!!!!!!!!!! for unpacked select
  const handleSelectOrdersFilter = async (e) => {
    //
    setData([]);
    orderCheckRef.current.checked = false;
    customerCheckRef.current.checked = false;
    setQuery("");

    setPageIndex(0);
    const resetPageIndex = 0;
    setSelectedFilter(e?.target?.value || selectedFilter);
    let filter = e?.target?.value || selectedFilter;
    if (filter === "completedorders") {
      fetchDataCompletedOrders(resetPageIndex, pageDataSize);
    }
    if (filter === "ordersawatinginvoice") {
      getAllDataOrdersAwatingInvoice(resetPageIndex, pageDataSize);
    }
    if (filter === "ordersawatingpayment") {
      getAllDataOrdersAwatingPayment(resetPageIndex, pageDataSize);
    }
    if (filter === "paidorders") {
      getAllDataPaidOrders(resetPageIndex, pageDataSize);
    }
    if (filter === "allorders") {
      fetchData(resetPageIndex, pageDataSize);
    }
  };

  // !!!!!!!!!!!!!!!!! packed selectores filters
  const handleSelectOrdersFilterPacked = async (e) => {
    //
    setPackedData([]);
    orderCheckRef.current.checked = false;
    customerCheckRef.current.checked = false;
    setQuery("");

    setPageIndexPacked(0);
    const resetPageIndex = 0;
    setSelectedFilterPacked(e?.target?.value || selectedFilterPacked);
    let filter = e?.target?.value || selectedFilterPacked;
    setPackedData([]);
    if (filter === "completedorderspacked") {
      fetchPackedDataCompletedOrders(resetPageIndex, pageDataSize);
    }

    if (filter === "ordersawatinginvoicepacked") {
      getAllPackedDataOrdersAwatingInvoice(resetPageIndex, pageDataSize);
    }
    if (filter === "ordersawatingpaymentpacked") {
      getAllPackedDataOrdersAwatingPayment(resetPageIndex, pageDataSize);
    }
    if (filter === "paidorderspacked") {
      getAllPackedDataPaidOrders(resetPageIndex, pageDataSize);
    }
    if (filter === "allorderspacked") {
      fetchPackedData(resetPageIndex, pageDataSize);
    }
  };

  // !!!!!!!!!!!!!!!!!!! filter funcitons for the packed orders !!!!!!!!!!!!!!!!!!!!!!!!!!!
  // const getallPackedOrderAwatingInvoice = async () => { };

  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  const handleFromDate = (e) => {
    setViewFromDate(e.target.value);
  };

  const handleToDate = (e) => {
    setViewToDate(e.target.value);
  };
  const handleFromDateSummary = (e) => {
    setSummaryFromDate(e.target.value);
  };

  const handleToDateSummary = (e) => {
    setSummaryToDate(e.target.value);
  };

  const [daterangePayload, setDaterangepayload] = useState({
    from: viewFromDate,
    to: viewToDate,
    page: pageDataIndex + 1,
  });

  const [daterangePackedPayload, setDaterangePackedpayload] = useState({
    from: viewFromDate,
    to: viewToDate,
  });

  const getDateRangeOrders = async (pageDataIndex, payload) => {
    let res = "";

    try {
      if (key === "unpacked") {
        const { page, ...rest } = payload;
        res = await postRequest(configURL.view_order, {
          ...rest,
          page: pageDataIndex,
        });

        let reversedData = res?.data?.data?.orders?.sort(
          (a, b) => b.orderno - a.orderno
        );

        if (res?.status === 200 || res?.status === 201) {
          // setData(res?.data?.data?.orders);
          setData(reversedData);

          setPageCount(res?.data?.data?.totalpages);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `No data found.`,
          });
        }

        return res;
      } else if (key === "packed") {
        res = await postRequest(
          `${configURL.search_packed_orders_by_date_rage}/${pageDataIndex}/`,
          payload
        );

        let reversedData = res?.data?.data?.package?.sort(
          (a, b) => b.orderno - a.orderno
        );

        if (res?.status === 200 || res?.status === 201) {
          // setPackedData(res?.data?.data?.package);
          setPackedData(reversedData);

          setPageCount(res?.data?.data?.totalpages);
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `No data found.`,
          });
        }

        return res;
      }
    } catch (error) {
      // console.log(
      //   "🚀 ~ file: ServerSideOrdersList.js:864 ~ getDateRangeOrders ~ error:",
      //   error
      // );
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `No data found.`,
      });
    }
  };

  const submitDateFun = async (e) => {
    e.preventDefault();

    let payload = {};

    if (key === "unpacked") {
      payload = {
        from: viewFromDate,
        to: viewToDate,
        page: pageDataIndex + 1,
      };
      setDaterangepayload((prevPayload) => ({
        ...prevPayload,
        from: viewFromDate,
        to: viewToDate,
        page: pageDataIndex + 1,
      }));
    } else if (key === "packed") {
      payload = {
        from: viewFromDate,
        to: viewToDate,
      };

      setDaterangePackedpayload((prevPayload) => ({
        ...prevPayload,
        from: viewFromDate,
        to: viewToDate,
      }));
    }

    if (payload.from === "" && payload.to === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select from and to date`,
      });
    } else if (payload.from === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select from date`,
      });
    } else if (payload.to === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Please select to date`,
      });
    } else {
      let res = await getDateRangeOrders(pageDataIndex + 1, payload);

      if (res.status === 201 || res.status === 200) {
        if (key === "unpacked") {
          setData(res?.data?.data?.orders);
          setPageCount(res?.data?.data?.totalpages);
          setSelectedFilter("unpackeddaterange");
        } else if (key === "packed") {
          setPackedData(res?.data?.data?.package);
          setPageCount(res?.data?.data?.totalpages);
          setSelectedFilterPacked("packeddaterange");
        }
      } else if (
        res.response.status === 400 ||
        res.response.status === 401 ||
        res.response.status === 404
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error ${res.response?.data?.message}`,
        });
        // console.log("error", res);
      } else {
        // console.log("errror", res);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `Error`,
        });
      }
    }
  };

  const submitDatesSummaryFun = async () => {
    let payload = {
      from: summaryFromDate,
      to: summaryToDate,
    };

    navigate(`/${RouteStrings.viewOrderSummary}`, { state: { data: payload } });
  };

  const getAllDataForTheSelectedFilter = async () => {
    // !!!!!!!FOR PACKED!!!!!!!!!!!

    if (key === "packed") {
      // if (selectedFilterPacked === "completedorderspacked") {
      //   await fetchPackedDataCompletedOrders(pageDataIndexPacked, pageDataSize);
      // }
      // if (selectedFilterPacked === "paidorderspacked") {
      //   await getAllPackedDataPaidOrders(pageDataIndexPacked, pageDataSize);
      // }

      // if (selectedFilterPacked === "ordersawatinginvoicepacked") {
      //   await getAllPackedDataOrdersAwatingInvoice(
      //     pageDataIndexPacked,
      //     pageDataSize
      //   );
      // }
      // // ordersawatingpaymentpacked
      // if (selectedFilterPacked === "ordersawatingpaymentpacked") {
      //   await getAllPackedDataOrdersAwatingPayment(
      //     pageDataIndexPacked,
      //     pageDataSize
      //   );
      // }
      // if (selectedFilterPacked === "allorderspacked") {
      //   await fetchPackedData(pageDataIndexPacked, pageDataSize);
      // }
      // else {
      //   await fetchPackedData(pageDataIndexPacked, pageDataSize);
      // }
      switch (selectedFilterPacked) {
        case "completedorderspacked":
          await fetchPackedDataCompletedOrders(
            pageDataIndexPacked,
            pageDataSize
          );
          break;
        case "paidorderspacked":
          await getAllPackedDataPaidOrders(pageDataIndexPacked, pageDataSize);
          break;
        case "ordersawatinginvoicepacked":
          await getAllPackedDataOrdersAwatingInvoice(
            pageDataIndexPacked,
            pageDataSize
          );
          break;
        case "ordersawatingpaymentpacked":
          await getAllPackedDataOrdersAwatingPayment(
            pageDataIndexPacked,
            pageDataSize
          );
          break;
        case "allorderspacked":
          await fetchPackedData(pageDataIndexPacked, pageDataSize);
          break;
        default:
          await fetchPackedData(pageDataIndexPacked, pageDataSize);
          break;
      }
    }
    // !!!!!!!!unpacked !!!!!!!!!!!!
    if (key === "unpacked") {
      // if (selectedFilter === "completedorders") {
      //   await fetchDataCompletedOrders(pageDataIndex, pageDataSize);
      // }

      // if (selectedFilter === "ordersawatinginvoice") {
      //   await getAllDataOrdersAwatingInvoice(pageDataIndex, pageDataSize);
      // }
      // if (selectedFilter === "ordersawatingpayment") {
      //   await getAllDataOrdersAwatingPayment(pageDataIndex, pageDataSize);
      // }
      // if (selectedFilter === "paidorders") {
      //   await getAllDataPaidOrders(pageDataIndex, pageDataSize);
      // }
      // if (selectedFilter === "allorders") {
      //   await fetchData(pageDataIndex, pageDataSize);
      // }
      // else {
      //   await fetchData(pageDataIndex, pageDataSize);
      // }
      switch (selectedFilter) {
        case "completedorders":
          await fetchDataCompletedOrders(pageDataIndex, pageDataSize);
          break;
        case "ordersawatinginvoice":
          await getAllDataOrdersAwatingInvoice(pageDataIndex, pageDataSize);
          break;
        case "ordersawatingpayment":
          await getAllDataOrdersAwatingPayment(pageDataIndex, pageDataSize);
          break;
        case "paidorders":
          await getAllDataPaidOrders(pageDataIndex, pageDataSize);
          break;
        case "allorders":
          await fetchData(pageDataIndex, pageDataSize);
          break;
        default:
          await fetchData(pageDataIndex, pageDataSize);
          break;
      }
    }
  };

  const callNextPage = async () => {
    // !!!!!!!FOR PACKED!!!!!!!!!!!
    if (key === "packed") {
      setPageCount(0);
      setPageIndexPacked(() => pageDataIndexPacked + 1);

      if (selectedFilterPacked === "completedorderspacked") {
        // await fetchDataCompletedOrders(pageDataIndex + 1, pageDataSize);
        await fetchPackedDataCompletedOrders(
          pageDataIndexPacked + 1,
          pageDataSize
        );
      }
      if (selectedFilterPacked === "paidorderspacked") {
        // await fetchDataCompletedOrders(pageDataIndex + 1, pageDataSize);
        await getAllPackedDataPaidOrders(pageDataIndexPacked + 1, pageDataSize);
      }

      if (selectedFilterPacked === "ordersawatinginvoicepacked") {
        // await getAllDataOrdersAwatingInvoice(pageDataIndex + 1, pageDataSize);
        await getAllPackedDataOrdersAwatingInvoice(
          pageDataIndexPacked + 1,
          pageDataSize
        );
      }

      if (selectedFilterPacked === "ordersawatingpaymentpacked") {
        // await getAllDataOrdersAwatingPayment(pageDataIndex + 1, pageDataSize);

        await getAllPackedOrdersAwatingPayment(
          pageDataIndexPacked + 1,
          pageDataSize
        );
      }
      if (selectedFilterPacked === "allorderspacked" || selectedFilterPacked === "") {
        // await fetchData(pageDataIndex, pageDataSize);
        await fetchPackedData(pageDataIndexPacked + 1, pageDataSize);
      }

      if (selectedFilterPacked === "packeddaterange") {
        setDaterangePackedpayload((prevPayload) => ({
          ...prevPayload,
          from: viewFromDate,
          to: viewToDate,
        }));

        await getDateRangeOrders(
          dateRangePageIndex + 1,
          daterangePackedPayload
        );
        setDateRangePageIndex(() => dateRangePageIndex + 1);
      }
    }
    // !!!!!!!!unpacked !!!!!!!!!!!!
    if (key === "unpacked") {
      setPageCount(0);
      setPageIndex(() => pageDataIndex + 1);

      if (selectedFilter === "completedorders") {
        await fetchDataCompletedOrders(pageDataIndex + 1, pageDataSize);
      }

      if (selectedFilter === "ordersawatinginvoice") {
        await getAllDataOrdersAwatingInvoice(pageDataIndex + 1, pageDataSize);
      }
      if (selectedFilter === "ordersawatingpayment") {
        await getAllDataOrdersAwatingPayment(pageDataIndex + 1, pageDataSize);
      }
      if (selectedFilter === "paidorders") {
        await getAllDataPaidOrders(pageDataIndex + 1, pageDataSize);
      }
      if (selectedFilter === "allorders" || selectedFilter === "") {
        await fetchData(pageDataIndex + 1, pageDataSize);
      }

      if (selectedFilter === "unpackeddaterange") {
        setDaterangepayload((prevPayload) => ({
          ...prevPayload,
          from: viewFromDate,
          to: viewToDate,
          page: dateRangePageIndex + 1,
        }));
        await getDateRangeOrders(dateRangePageIndex + 1, daterangePayload);
        setDateRangePageIndex(() => dateRangePageIndex + 1);
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const callPrevPage = async () => {
    if (key === "unpacked") {
      setPageIndex(() => pageDataIndex > 0 && pageDataIndex - 1);

      if (selectedFilter === "completedorders") {
        await fetchDataCompletedOrders(
          pageDataIndex > 0 && pageDataIndex - 1,
          pageDataSize
        );
      }

      if (selectedFilter === "ordersawatinginvoice") {
        await getAllDataOrdersAwatingInvoice(
          pageDataIndex > 0 && pageDataIndex - 1,
          pageDataSize
        );
      }
      if (selectedFilter === "ordersawatingpayment") {
        await getAllDataOrdersAwatingPayment(
          pageDataIndex > 0 && pageDataIndex - 1,
          pageDataSize
        );
      }
      if (selectedFilter === "paidorders") {
        await getAllDataPaidOrders(
          pageDataIndex > 0 && pageDataIndex - 1,
          pageDataSize
        );
      }
      if (selectedFilter === "allorders" || selectedFilter === "") {
        await fetchData(pageDataIndex > 0 && pageDataIndex - 1, pageDataSize);
      }

      if (selectedFilter === "unpackeddaterange") {
        if (dateRangePageIndex > 0) {
          setDaterangepayload((prevPayload) => ({
            ...prevPayload,
            from: viewFromDate,
            to: viewToDate,
            page: dateRangePageIndex - 1,
          }));
          await getDateRangeOrders(dateRangePageIndex - 1, daterangePayload);
          setDateRangePageIndex(() => dateRangePageIndex - 1);
        }
      }
    }

    if (key === "packed") {
      setPageIndexPacked(
        () => pageDataIndexPacked > 0 && pageDataIndexPacked - 1
      );

      if (selectedFilterPacked === "completedorderspacked") {
        await fetchPackedDataCompletedOrders(
          pageDataIndexPacked > 0 && pageDataIndexPacked - 1,
          pageDataSize
        );
      }
      if (selectedFilterPacked === "paidorderspacked") {
        await getAllPackedDataPaidOrders(
          pageDataIndexPacked > 0 && pageDataIndexPacked - 1,
          pageDataSize
        );
      }

      if (selectedFilterPacked === "ordersawatinginvoicepacked") {
        await getAllPackedDataOrdersAwatingInvoice(
          pageDataIndexPacked > 0 && pageDataIndexPacked - 1,
          pageDataSize
        );
      }
      if (selectedFilterPacked === "ordersawatingpaymentpacked") {
        await getAllPackedDataOrdersAwatingPayment(
          pageDataIndexPacked > 0 && pageDataIndexPacked - 1,
          pageDataSize
        );
      }
      if (selectedFilterPacked === "allorderspacked" || selectedFilterPacked === "") {
        await fetchPackedData(
          pageDataIndexPacked > 0 && pageDataIndexPacked - 1,
          pageDataSize
        );
      }

      if (selectedFilterPacked === "packeddaterange") {
        if (dateRangePageIndex > 0) {
          setDaterangePackedpayload((prevPayload) => ({
            ...prevPayload,
            from: viewFromDate,
            to: viewToDate,
          }));

          await getDateRangeOrders(
            dateRangePageIndex - 1,
            daterangePackedPayload
          );
          setDateRangePageIndex(() => dateRangePageIndex - 1);
        }
      }
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const goToLastPage = async (count) => {
    setPageIndex(count);
    if (selectedFilter === "completedorders") {
      await fetchDataCompletedOrders(count, pageDataSize);
    }

    if (selectedFilter === "ordersawatinginvoice") {
      await getAllDataOrdersAwatingInvoice(count, pageDataSize);
    }
    if (selectedFilter === "ordersawatingpayment") {
      await getAllDataOrdersAwatingPayment(count, pageDataSize);
    }
    if (selectedFilter === "paidorders") {
      await getAllDataPaidOrders(count, pageDataSize);
    } else if (selectedFilter === "allorders" || selectedFilter === "") {
      await fetchData(count, pageDataSize);
    }

    if (selectedFilter === "unpackeddaterange") {
      setDaterangepayload((prevPayload) => ({
        ...prevPayload,
        from: viewFromDate,
        to: viewToDate,
        page: count,
      }));
      await getDateRangeOrders(count + 1, daterangePayload);
      setDateRangePageIndex(count + 1);
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const goToLastPagePacked = async (count) => {
    setPageIndexPacked(count);
    if (selectedFilterPacked === "completedorderspacked") {
      await fetchPackedDataCompletedOrders(count, pageDataSize);
    }

    if (selectedFilterPacked === "ordersawatinginvoicepacked") {
      await getAllPackedDataOrdersAwatingInvoice(count, pageDataSize);
    }
    if (selectedFilterPacked === "ordersawatingpaymentpacked") {
      await getAllPackedDataOrdersAwatingPayment(count, pageDataSize);
    }
    if (selectedFilterPacked === "paidorderspacked") {
      await getAllPackedDataPaidOrders(count, pageDataSize);
    }
    if (selectedFilterPacked === "allorderspacked" || selectedFilterPacked === "") {
      await fetchPackedData(count, pageDataSize);
    }

    if (selectedFilterPacked === "packeddaterange") {
      setDaterangePackedpayload((prevPayload) => ({
        ...prevPayload,
        from: viewFromDate,
        to: viewToDate,
      }));

      await getDateRangeOrders(count + 1, daterangePackedPayload);
      setDateRangePageIndex(count + 1);
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const clearSerachfield = () => {
    searchInpRef.current.value = "";
    orderCheckRef.current.checked = false;
    customerCheckRef.current.checked = false;
    setQuery("");
    setSearchText("");
  }

  const clearText = () => {
    clearSerachfield()
    if (key === "unpacked") {
      (selectedFilter == "allorders" || selectedFilter == "") &&
        fetchData(pageDataIndex, pageDataSize);
    }
    if (key === "packed") {
      setPageIndexPacked(0);
      fetchPackedData(pageDataIndex, pageDataSize);
    }
  };

  const combineOrderFun = async () => {
    const orderNumbers = [];

    let orders = selectedFlatRows;

    orders.map((item) => {
      orderNumbers.push(item?.original?.orderno);
    });

    let payload = {
      ordernos: orderNumbers.join(","),
    };

    // let res = await CombineOrderService(payload);
    let res = await postRequest(configURL.combineOrders, payload);

    // setLoading(true);
    if (res?.status === 200 || res?.status === 201) {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `Combined Orders Successfully!`,
      });
      setCheckedOrders([]);
      handleClearSelection();
      fetchData(pageDataIndex, pageDataSize);
      // setLoading(false);
    } else {
      setCheckedOrders([]);
      handleClearSelection();
      Swal.fire({
        icon: "Error",
        text: `Error : ${res?.response?.data?.message}`,
      });
      // setLoading(false);
    }
  };

  const printallinvoicedata = async () => {
    if (key === "unpacked") {
      const response = await getRequest(
        `${configURL.get_all_unpacked_invoiced_orders}`
      );

      let stocks = response?.data?.data?.orders;

      let stock_details = [];

      stocks?.map((item) => {
        if (item?.status === "1") {
          // item?.stock_details?.map((stock) => {
          //   stock_details.push(stock);
          // });
          stock_details.push(item);
        }
      });

      navigate(RouteStrings.printIvoicedOrders, {
        state: { data: stock_details, key: key },
      });
    }

    if (key === "packed") {
      const response = await getRequest(
        `${configURL.get_all_packed_invoiced_orders}`
      );

      let packed_orders = response?.data?.data?.packages;

      let stock_details_packed = [];

      // packed_orders?.map((order) => {
      //   if (order?.status === "1") {
      //     order?.orders?.map((mapOrder) => {
      //       mapOrder?.order_item?.map((item) => {
      //         stock_details_packed.push(item);
      //       });
      //     });
      //   }
      // });

      packed_orders?.map((order) => {
        if (order?.status === "1") {
          order?.orders?.map((mapOrder) => {
            // mapOrder?.order_item?.map((item) => {
            //   stock_details_packed.push(item);
            // });
            stock_details_packed.push(mapOrder);
          });
        }
      });

      navigate(RouteStrings.printIvoicedOrders, {
        state: { data: stock_details_packed, key: key },
      });
    }
  };

  return (
    <>
      <>
        <div>
          {/* <div className="text-right">
            <button
              className="btn btn-info mr-1 rounded-0"
              onClick={gotoGraphicalSales}
            >
              Graphical Sales Analysis
            </button>
          </div> */}

          <div className="showDateRangeDiv mb-4">
            <h5 className="mb-3">View Orders</h5>
            <p className="mb-0">Select the Dates to view All orders </p>
            <div className="my-1 d-flex align-items-center">
              <span>From : </span>
              <input
                type="date"
                name="from"
                id="from"
                onChange={handleFromDateSummary}
                className="inpdate mx-2"
              />
              <span>To : </span>
              <input
                type="date"
                name="to"
                id="to"
                onChange={handleToDateSummary}
                className="inpdate mx-2"
              />
              <button
                className="btn btn-primary mr-1 rounded-0"
                id="submitbuttonsummary"
                onClick={submitDatesSummaryFun}
              >
                Submit
              </button>
            </div>
          </div>

          {/* //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! add the tabs here!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              setIsPacked(k);
              setPageIndex(0);
              setPageIndexPacked(0);
              setData([]);
              setPackedData([]);
              setViewToDate("");
              setViewFromDate("");
              setSummaryToDate("");
              setSummaryFromDate("");
              setDateRangePageIndex(1);
              setSelectedFilter("");
              setSelectedFilterPacked("");

              viewFromDateRef.current.value = "";
              viewToDateRef.current.value = "";
              viewFromSummaryDateRef.current.value = "";
              viewToSummaryDateRef.current.value = "";
              // -----------
              // searchInpRef.current.value = "";
              // setSearchText("");
              // to clear the text from the search filed and also the radio button uncheck
              // clearText();
              clearSerachfield()

              // --------
              selectfilterforunpackedref.current.value = "Select filter...";
              selectfilterforpackedref.current.value = "Select filter...";
              callOrdersfun(k);
            }}
            className="my-3"
          >
            <Tab eventKey="unpacked" title="Unpacked Orders">
              <>
                <div className="showDateRangeDiv">
                  <p className="mb-0">
                    Select the dates to view unpacked orders
                  </p>
                  <div className="my-1 d-flex align-items-center">
                    <span>From : </span>
                    <input
                      type="date"
                      name="from"
                      id="from"
                      ref={viewFromDateRef}
                      onChange={handleFromDate}
                      className="inpdate mx-2"
                    />
                    <span>To : </span>
                    <input
                      type="date"
                      name="to"
                      ref={viewToDateRef}
                      id="to"
                      onChange={handleToDate}
                      className="inpdate mx-2"
                    />
                    <button
                      className="btn btn-primary mr-1 rounded-0"
                      id="submitbutton"
                      onClick={submitDateFun}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>

              <p className="mb-0">Filter Orders for unpacked orders</p>
              <div class="form-group">
                <select
                  className="form-control ordersFilter"
                  onChange={handleSelectOrdersFilter}
                  ref={selectfilterforunpackedref}
                  id="exampleFormControlSelect1"
                >
                  <option selected disabled>
                    Select filter...
                  </option>
                  <option value="ordersawatinginvoice">
                    Orders Awating Invoice
                  </option>
                  <option value="ordersawatingpayment">
                    Orders Awaiting Payment
                  </option>
                  <option value="paidorders">Paid Orders</option>
                  <option value="completedorders">Completed Orders</option>
                  <option value="allorders">All Orders</option>
                </select>
              </div>
            </Tab>
            <Tab eventKey="packed" title="Packed Orders">
              <>
                <div className="showDateRangeDiv">
                  <p className="mb-0">Select the dates to view packed orders</p>
                  <div className="my-1 d-flex align-items-center">
                    <span>From : </span>
                    <input
                      type="date"
                      name="from"
                      id="from"
                      ref={viewFromSummaryDateRef}
                      onChange={handleFromDate}
                      className="inpdate mx-2"
                    />
                    <span>To : </span>
                    <input
                      type="date"
                      name="to"
                      id="to"
                      ref={viewToSummaryDateRef}
                      onChange={handleToDate}
                      className="inpdate mx-2"
                    />
                    <button
                      className="btn btn-primary mr-1 rounded-0"
                      id="submitbutton"
                      onClick={submitDateFun}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>

              <p className="mb-0">Filter Orders for packed orders</p>
              <div class="form-group">
                <select
                  className="form-control ordersFilter"
                  ref={selectfilterforpackedref}
                  onChange={handleSelectOrdersFilterPacked}
                  id="exampleFormControlSelect1"
                >
                  <option selected disabled>
                    Select filter...
                  </option>
                  <option value="ordersawatinginvoicepacked">
                    Orders Awating Invoice
                  </option>
                  <option value="ordersawatingpaymentpacked">
                    Orders Awaiting Payment
                  </option>
                  <option value="paidorderspacked">Paid Orders</option>

                  <option value="completedorderspacked">
                    Completed Orders
                  </option>
                  <option value="allorderspacked">All Orders</option>
                </select>
              </div>
            </Tab>
          </Tabs>

          <div className=" d-flex align-items-center my-2 w-100">
            <div className="position-relative w-25">
              <input
                type="text"
                name="search"
                id="search"
                ref={searchInpRef}
                className="inp"
                onChange={handleSearch}
                placeholder="Enter order no or customer name"
              />
              <img
                src={Images.cancel}
                alt=""
                className="delIcon"
                onClick={() => clearText()}
              />
            </div>

            <div className="selectEmailOrNameDiv d-flex mx-2">
              <Form>
                <Form.Group>
                  <div className="d-flex">
                    <Form.Check
                      type="radio"
                      name="currentlyBuying"
                      label={key === "unpacked" ? "Order Id" : "Package Id"}
                      ref={orderCheckRef}
                      value="true"
                      onChange={handleChangeEmail}
                    />
                    <Form.Check
                      type="radio"
                      name="currentlyBuying"
                      ref={customerCheckRef}
                      className="mx-2"
                      label="Customer Name"
                      value="false"
                      onChange={handleChangeName}
                    />
                  </div>
                </Form.Group>
              </Form>
            </div>
            <div>
              <button
                className="btn btn-info rounded-0"
                id="searchfunctionbutton"
                onClick={searchFuntion}
              >
                Search
              </button>
            </div>
            {selectedFlatRows.length > 0 && (
              <>
                <button
                  className="btn btn-success rounded-0 mx-1"
                  id="combineorderbutton"
                  onClick={combineOrderFun}
                >
                  Combine Orders
                </button>
              </>
            )}

            {selectedFilter === "ordersawatinginvoice" && (
              <>
                <div style={{ marginLeft: "auto" }}>
                  <button
                    className="btn btn-info rounded-0"
                    id="printallbutton"
                    onClick={printallinvoicedata}
                  >
                    Print All
                  </button>
                </div>
              </>
            )}

            {selectedFilterPacked === "ordersawatinginvoicepacked" &&
              packedData.length > 0 && (
                <>
                  <div style={{ marginLeft: "auto" }}>
                    <button
                      className="btn btn-info rounded-0"
                      id="printallbutton"
                      onClick={printallinvoicedata}
                    >
                      Print All
                    </button>
                  </div>
                </>
              )}
          </div>

          {/* table div */}

          <>
            <div>
              {key === "unpacked" && (
                <>
                  <ListingOrdersComponent
                    testdata="data for unpacked"
                    data={data}
                    getAllDataForTheSelectedFilter={
                      getAllDataForTheSelectedFilter
                    }
                  />
                </>
              )}

              {key === "packed" && (
                <>
                  <ListingPackedOrders
                    data={packedData}
                    getAllDataForTheSelectedFilter={
                      getAllDataForTheSelectedFilter
                    }
                  />
                </>
              )}

              {/* Pagination controls */}

              {key === "packed" && packedData.length > 0 && (
                <>
                  {pageCount > 0 && (
                    <div className="d-flex align-items-center mb-3">
                      <div className="w-100 text-center mt-3 mb-1">
                        <button
                          className="mx-1 skipToBtn"
                          id="lastpagebutton"
                          onClick={
                            key === "unpacked"
                              ? () => goToLastPage(0)
                              : () => goToLastPagePacked(0)
                          }
                          disabled={
                            key === "packed"
                              ? !(pageDataIndexPacked > 0)
                              : !(pageDataIndex > 0)
                          }
                        >
                          {"<<"}
                        </button>
                        <button
                          className="mx-1 actionBtn"
                          id="prevpagebutton"
                          onClick={() => callPrevPage()}
                          disabled={
                            key === "packed"
                              ? !(pageDataIndexPacked > 0)
                              : !(pageDataIndex > 0)
                          }
                        >
                          Prev
                        </button>
                        <span className="mx-2 pageNumber">
                          <strong>
                            {key === "unpacked"
                              ? `${pageDataIndex + 1}`
                              : `${pageDataIndexPacked + 1}`}
                          </strong>{" "}
                        </span>
                        <button
                          className="mx-1 actionBtn"
                          id="nextpagebutton"
                          onClick={() => {
                            callNextPage();
                          }}
                          disabled={
                            key === "packed"
                              ? pageDataIndexPacked >= pageCount - 1
                              : pageDataIndex >= pageCount - 1
                          }
                        >
                          Next
                        </button>
                        <button
                          className="skipToBtn"
                          id="lastpagebutton"
                          onClick={
                            key === "unpacked"
                              ? () => goToLastPage(pageCount - 1)
                              : () => goToLastPagePacked(pageCount - 1)
                          }
                          disabled={
                            key === "packed"
                              ? pageDataIndexPacked >= pageCount - 1
                              : pageDataIndex >= pageCount - 1
                          }
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}

              {key === "unpacked" && data.length > 0 && (
                <>
                  {pageCount > 0 && (
                    <>
                      <div className="d-flex align-items-center mb-3">
                        <div className="w-100 text-center mt-3 mb-1">
                          <button
                            className="mx-1 skipToBtn"
                            id="lastpageunpackedbutton"
                            onClick={
                              key === "unpacked"
                                ? () => goToLastPage(0)
                                : () => goToLastPagePacked(0)
                            }
                            disabled={
                              key === "packed"
                                ? !(pageDataIndexPacked > 0)
                                : !(pageDataIndex > 0)
                            }
                          >
                            {"<<"}
                          </button>
                          <button
                            className="mx-1 actionBtn"
                            id="prevpageunpackedbutton"
                            onClick={() => callPrevPage()}
                            disabled={
                              key === "packed"
                                ? !(pageDataIndexPacked > 0)
                                : !(pageDataIndex > 0)
                            }
                          >
                            Prev
                          </button>
                          <span className="mx-2 pageNumber">
                            <strong>
                              {key === "unpacked"
                                ? `${pageDataIndex + 1}`
                                : `${pageDataIndexPacked + 1}`}
                            </strong>{" "}
                          </span>
                          <button
                            className="mx-1 actionBtn"
                            id="nextpageunpackedbutton"
                            onClick={() => {
                              callNextPage();
                            }}
                            disabled={
                              key === "packed"
                                ? pageDataIndexPacked >= pageCount - 1
                                : pageDataIndex >= pageCount - 1
                            }
                          >
                            Next
                          </button>
                          <button
                            className="skipToBtn"
                            id="lastpageunpackedbutton"
                            onClick={
                              key === "unpacked"
                                ? () => goToLastPage(pageCount - 1)
                                : () => goToLastPagePacked(pageCount - 1)
                            }
                            disabled={
                              key === "packed"
                                ? pageDataIndexPacked >= pageCount - 1
                                : pageDataIndex >= pageCount - 1
                            }
                          >
                            {">>"}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      </>
    </>
  );
}

export default ServerSideOrdersListComponent;
