// let baseURl = `${process.env.REACT_APP_BASE_URL}`;

// the STAGING base url
// let baseURl = "https://stagingapi.pennymead.com";

// the base url for PROD
let baseURl = "https://newapi.pennymead.com";

export const configURL = {
  //  all count api dashboard
  get_all_count: `${baseURl}/admin/get_dashboard_count/`,

  //  admin login
  auth_login: `${baseURl}/admin/login/`,
  verify_otp: `${baseURl}/admin/verify_otp/`,
  forgot_password: `${baseURl}/admin/forgot_password/`,
  reset_password: `${baseURl}/admin/reset_password`,
  get_countries: `${baseURl}/admin/get_country/`,
  resend_otp: `${baseURl}/admin/resend_otp`,

  // admin routes
  get_all_admins: `${baseURl}/admin/get_admins/`,
  add_new_admin: `${baseURl}/admin/add_admin/`,
  delete_admin: `${baseURl}/admin/delete_admin/`,
  edit_admin: `${baseURl}/admin/update_admin`,
  make_super_admin: `${baseURl}/admin/make_superadmin/`,

  //  customer routes
  get_all_customers: `${baseURl}/admin/read_customers`,
  add_new_customer: `${baseURl}/admin/add_new_customer/`,
  get_customer_byId: `${baseURl}/admin/read_customer_by_id`,
  edit_customer_byId: `${baseURl}/admin/change_status`,
  update_customerData: `${baseURl}/admin/update_customer/`,
  search_customerData: `${baseURl}/admin/search_customer`,

  //  get all countries
  // get_all_countries: `${baseURl}/admin/get_country/`,

  get_all_countries: `${baseURl}/view/countries/`,

  //  get all subjects
  get_all_subjects: `${baseURl}/admin/get_subjects/`,
  // get all wi list
  get_all_wi_list: `${baseURl}/admin/get_wilists/`,

  //  great britain list
  get_all_great_britain_list: `${baseURl}/admin/get_gblists/`,

  //  get all categories
  get_all_categories_master: `${baseURl}/admin/get_categories/`,

  get_all_customers_master: `${baseURl}/admin/get_all_customers/`,

  //  category management urls
  get_all_categories: `${baseURl}/admin/read_cat/`,
  edit_category_data: `${baseURl}/admin/update_cat/`,
  delete_category: `${baseURl}/admin/delete_cat`,
  add_new_category: `${baseURl}/admin/add_category/`,
  get_all_groups: `${baseURl}/admin/read_groups/`,
  delete_group: `${baseURl}/admin/remove_group_from_cat`,
  add_new_group: `${baseURl}/admin/add_group/`,
  add_new_subCategory: `${baseURl}/admin/add_subcats/`,
  get_all_subCategory: `${baseURl}/admin/read_subcats/`,
  edit_sub_category: `${baseURl}/admin/update_subcats/`,
  delete_subCategories: `${baseURl}/admin/delete_subcats`,
  get_all_groups_basedon_categories: `${baseURl}/admin/get_category_group_list`,
  get_allsubcategories_basedon_group: `${baseURl}/admin/get_allsubcats_for_category`,
  get_all_hiddensubcategories_lsit: `${baseURl}/admin/get_hide_catsubcats_detail_for_group`,
  get_all_groups_toadd_category_list: `${baseURl}/admin/get_group_to_add_categories`,
  show_subcategories_to_categories: `${baseURl}/admin/show_subcats_to_category/`,
  hide_subcategory_to_categories: `${baseURl}/admin/hide_subcats_to_category/`,
  add_group_to_category: `${baseURl}/admin/show_group_categories`,

  //  stock management endpoints
  add_new_stock: `${baseURl}/admin/add_stock_item/`,
  search_stock: `${baseURl}/admin/search_stock_item/`,
  update_stock: `${baseURl}/admin/update_stock_item/`,
  add_stock_images: `${baseURl}/admin/add_stock_image/`,
  remove_stock_image: `${baseURl}/admin/remove_stock_image`,
  change_stock_status: `${baseURl}/admin/change_stock_status`,
  get_stock_by_id: `${baseURl}/admin/get_stock_item`,
  get_subcategoriesByid: `${baseURl}/admin/get_allsubcats_for_category`,
  get_printed_catalog: `${baseURl}/admin/gen_printed_catalog/`,
  export_data_images: `${baseURl}/admin/export_stock_item/`,
  complete_export: `${baseURl}/admin/complete_export/`,
  get_exported_stock_date: `${baseURl}/admin/last_export_date/`,
  get_all_stock_locations: `${baseURl}/admin/stock_location/`,
  get_all_total_active_stocks: `${baseURl}/admin/total_active_stocks/`,

  //  Order management endpoints
  get_all_orders: `${baseURl}/admin/read_all_orders`,
  search_order: `${baseURl}/admin/search_orders/`,
  // get_all_completedOrders: `${baseURl}/admin/read_completed_orders`,
  get_all_completedOrders: `${baseURl}/admin/read_completed_orders`,

  get_all_ordersAwatingInvoice: `${baseURl}/admin/read_order_awaiting_invoice`,
  get_all_ordersAwatingPayment: `${baseURl}/admin/read_order_awaiting_payment`,
  delete_order: `${baseURl}/admin/delete_order`,
  view_order: `${baseURl}/admin/read_orders_by_daterange/`,
  view_order_summary: `${baseURl}/admin/order_summary/`,
  get_order_byOrderno: `${baseURl}/admin/order_detail_by_orderno`,
  add_additional_orderDeatils: `${baseURl}/admin/add_additional_order_details/`,
  send_order_invoice: `${baseURl}/admin/send_invoice`,
  edit_address: `${baseURl}/admin/edit_order_address/`,
  send_payment_reminder: `${baseURl}/admin/send_payment_reminder`,
  mark_payment_received: `${baseURl}/admin/mark_payment_received`,
  get_all_graphs_by_month: `${baseURl}/view/graph/`,
  category_performance_items_sold: `${baseURl}/view/category-performance/`,
  category_performance_by_sales: `${baseURl}/view/category-performance-sale/`,
  bidsonsalebytender: `${baseURl}/admin/bids_on_item/`,
  combineOrders: `${baseURl}/admin/combine_order/`,

  // read all packed orders -- prior one
  // get_all_packed_orders: `${baseURl}/admin/read_all_packed_orders`,

  get_packed_order_details: `${baseURl}/admin/packedorder_detail_by_packageid`,

  // read all orders for packed orders awating invoice ---- prior one.
  // get_packed_orders_awating_invoice: `${baseURl}/admin/read_packedorder_awaiting_invoice`,

  // get all data for packed ordes for order awating payment -- prior one
  // get_packed_orders_awating_payment: `${baseURl}/admin/read_packedorder_awaiting_payment`,

  // get all data for packed paid orders -- prior one
  // get_all_packed_paid_orders: `${baseURl}/admin/read_paid_packedorders`,

  // get all packed completed orders -- prior one
  // get_packed_completed_orders: `${baseURl}/admin/read_completed_packedorders`,

  add_additional_packed_order: `${baseURl}/admin/edit_packedorder_address/`,
  add_postage_price_change_packed: `${baseURl}/admin/add_additional_packedorder_details/`,
  sendInvoice_packed_order: `${baseURl}/admin/send_invoice_package`,
  send_payment_remainder_packed: `${baseURl}/admin/send_payment_reminder_package`,
  get_all_unpacked_paid_orders: `${baseURl}/admin/read_paid_order`,

  changeunpackedorderstatus: `${baseURl}/admin/change_order_status/`,

  changepackedorderstatus: `${baseURl}/admin/change_packed_order_status/`,

  // search packed order -- prior one.
  // search_packed_orders: `${baseURl}/admin/search_packedorders/`,

  // read all packed orders by date range -- prior one
  // search_packed_orders_by_date_rage: `${baseURl}/admin/read_packedorders_by_daterange/`,

  mark_payment_received_packed_order: `${baseURl}/admin/mark_payment_received_package`,

  // unpacked order -- prior one
  unpacke_orders: `${baseURl}/admin/undo_package`,

  // to print the invoiced orders url
  get_all_unpacked_invoiced_orders: `${baseURl}/admin/read_all_order_awaiting_invoice/`,
  get_all_packed_invoiced_orders: `${baseURl}/admin/read_all_packagedorder_awaiting_invoice/`,

  //   endpoints for the packed orders upated screen !!!!!!!!!!!!!!!!!!!!!
  get_all_packed_orders: `${baseURl}/admin/read_all_package_orders`,
  get_packed_orders_awating_invoice: `${baseURl}/admin/read_packagedorder_awaiting_invoice`,
  get_packed_orders_awating_payment: `${baseURl}/admin/read_packageorder_awaiting_payment`,
  get_all_packed_paid_orders: `${baseURl}/admin/read_paid_packageorders`,
  get_packed_completed_orders: `${baseURl}/admin/read_completed_packageorders`,
  search_packed_orders_by_date_rage: `${baseURl}/admin/read_packageorders_by_daterange`,
  search_packed_orders: `${baseURl}/admin/search_packageorders`,

  //  Mailing list urls
  get_all_mailing_list: `${baseURl}/admin/mailing_list`,

  // postage management
  get_all_postage_details: `${baseURl}/admin/read_postage_details/`,
  update_postage_rate: `${baseURl}/admin/update_postage_rate/`,

  // global settings url
  get_all_global_settings: `${baseURl}/admin/read_settings/`,
  update_global_settings: `${baseURl}/admin/update_sitesettings/`,

  // Holiday Settings url
  add_new_holiday: `${baseURl}/admin/turn_on_holiday/`,
  turn_off_holiday: `${baseURl}/admin/turn_off_holiday/`,
  read_holiday_info: `${baseURl}/admin/read_active_holiday/`,

  //  CMS section -- admin info
  get_admin_info: `${baseURl}/cms/getaboutpage_content/`,
  add_update_content: `${baseURl}/cms/addorupdateaboutpage_content/`,

  // contact us
  get_all_contact_details: `${baseURl}/cms/getcontactpage_content/`,
  add_contactus_terms: `${baseURl}/cms/addorupdatecontactpage_content/`,

  // note section
  get_all_notes: `${baseURl}/cms/latestnote/`,
  add_new_note: `${baseURl}/cms/addorupdatenote_content/`,
  get_all_tips: `${baseURl}/cms/helpnotes/`,
  get_all_help_note_type: `${baseURl}/admin/read_helpnote_types/`,
  add_new_tip: `${baseURl}/admin/add_helpnote/`,
  delete_tip: `${baseURl}/admin/delete_helpnote`,

  // page management urls

  get_all_subcategories_pm: `${baseURl}/admin/get_allsubcats_for_category_group`,

  get_all_category_content: `${baseURl}/cms/getcatpage_content`,

  edit_category_info: `${baseURl}/cms/addorupdatecatpage_content/`,

  get_all_subcategories_content: `${baseURl}/cms/getsubcatpage_content`,

  add_update_subcategory_content: `${baseURl}/cms/addorupdatesubcatpage_content/`,

  // new apis for stock

  getshowgroupsforcategories: `${baseURl}/admin/get_show_subcats_from_group`,
};
