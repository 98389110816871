import React, { useState, useEffect } from "react";
import { AuthBannerComponent } from "../../../Components/AuthBanner";
import { RouteStrings } from "../../../Routes/RouteStrings";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { loggedIn } from "../../../Store/reducers/reducers";
// import { pennymead_SetLocal } from "../../../Utils/LocalStorage";
import {
  adminLoginService,
  getAllCountriesService,
} from "../../../Services/AuthServices";
import Swal from "sweetalert2";
import BounceLoader from "react-spinners/BounceLoader";
import { Images } from "../../../Assets/Images/imageslist";

// SPINNERS

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export const LoginComponent = () => {

  let [loading, setLoading] = useState(false);

  const [passwordType, setPasswordType] = useState("password");
  const [showHide, setShowHide] = useState(true);

  const { isLogin } = useSelector((state) => state.UIStorage);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogin) {
      navigate(RouteStrings.dashboard)
    }
  }, [isLogin]);



  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const [errorMsg, setErrorMsg] = useState({
    email: "",
    password: "",
  });

  const gotoregister = () => {
    navigate(RouteStrings.register);
  };
  const gotoTwoFactorAuth = (e) => {
    e.preventDefault();
    navigate(RouteStrings.twofactorAuth);
  };
  const gotoForgotPassword = () => {
    navigate(RouteStrings.forgotPassword);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "email") {
      setError({
        ...error,
        email: false,
      });
      setEmail(value);
    }

    if (name === "password") {
      setError({
        ...error,
        password: false,
      });
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === "" && password === "") {
      setError({
        ...error,
        email: true,
        password: true,
      });
      setErrorMsg({
        email: "Please enter the email",
        password: "Please enter the password",
      });
    } else if (email === "") {
      setError({
        ...error,
        email: true,
      });
      setErrorMsg({
        email: "Please enter the email",
      });
    } else if (password === "") {
      setError({
        ...error,
        password: true,
      });
      setErrorMsg({
        password: "Please enter the password",
      });
    } else {
      setLoading(true);
      let payload = {
        username: email,
        password: password,
      };

      let res = await adminLoginService(payload);

      if (res.status === 200) {
        setLoading(false);
        navigate(RouteStrings.twofactorAuth, { state: { data: res.data } });
      } else if (res.response.status === 400) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          // title: res?.status,
          text: `Error, ${res.response.data.message}`,
        });
      } else if (res.response.status === 404) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          // title: res?.status,
          text: `Error, ${res.response.data.message}`,
        });
      }
    }
  };

  const showHideFun = () => {
    if (!showHide) {
      setShowHide(true);
      setPasswordType("password");
    } else {
      setShowHide(false);
      setPasswordType("text");
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <AuthBannerComponent screenHeading="Pennymead.com" />
        </div>
        {loading ? (
          <>
            <div className="d-flex justify-content-center align-items-center loaderCSS w-50">
              <BounceLoader
                color="#873900"
                loading={loading}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-md-6">
              <div className="d-flex align-items-center justify-content-center h-100">
                <div className="card login_main px-3">
                  <h1 className="heading mb-2">Login here</h1>
                  {/* <p className="_text">
                Please enter your email and password to login
              </p> */}

                  <div className="login_form">
                    <form onSubmit={handleSubmit}>
                      <div className="form_content mb-2">
                        <label htmlFor="">Email</label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="input"
                          onChange={handleChange}
                          placeholder="Please enter your email"
                        />

                        {error.email && (
                          <>
                            <p className="text-danger mb-0">{errorMsg.email}</p>
                          </>
                        )}
                      </div>
                      <div className="form_content mb-2 passworddiv position-relative w-75">
                        <label htmlFor="">Password</label>
                        <input
                          type={passwordType}
                          name="password"
                          id="password"
                          onChange={handleChange}
                          className="input w-100"
                          placeholder="Please enter your password"
                        />
                        {showHide ? (
                          <img
                            src={Images.hide}
                            alt=""
                            className="showHideIcon"
                            onClick={showHideFun}
                          />
                        ) : (
                          <img
                            src={Images.show}
                            alt=""
                            className="showHideIcon"
                            onClick={showHideFun}
                          />
                        )}
                        {error.password && (
                          <>
                            <p className="text-danger mb-0">
                              {errorMsg.password}
                            </p>
                          </>
                        )}
                      </div>

                      <div className="mb-2 d-flex justify-content-end w-75">
                        <p
                          className="_forgotpassword mb-0"
                          onClick={gotoForgotPassword}
                        >
                          Forgot Password?
                        </p>
                      </div>

                      <div className="mb-2">
                        <button
                          className="btn btn-primary btn_login"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
